import * as Ariakit from "@ariakit/react";
import { useState } from "react";
import { LogoGlyph } from "./logo-glyph";
import { TextAa } from "@phosphor-icons/react";
import glyph from "../assets/brand/replicate-glyph.svg";
import wordmark from "../assets/brand/replicate-wordmark.svg";

function downloadSvg(filename: string, svg: string) {
  const link = document.createElement("a");
  link.href = svg;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function LogoMenu() {
  const [anchorRect, setAnchorRect] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const menu = Ariakit.useMenuStore({ focusLoop: true });

  const handleDownloadGlyph = () => {
    downloadSvg("replicate-glyph.svg", glyph.src);
  };

  const handleDownloadWordmark = () => {
    downloadSvg("replicate-wordmark.svg", wordmark.src);
  };

  return (
    <>
      <a
        onContextMenu={(event) => {
          event.preventDefault();
          const bbox = event.currentTarget.getBoundingClientRect();
          setAnchorRect({ x: bbox.x, y: bbox.y + bbox.height });
          menu.show();
        }}
        href="/"
        className="block"
        style={{ width: 182 }}
      >
        {/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
        <svg
          className="logo hardware-accelerated mt-2"
          viewBox="0 0 105 25"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24.6212 8.04688C27.5294 8.04688 29.9739 9.97587 29.9739 13.5583C29.9739 13.7963 29.9739 13.9967 29.9488 14.3599H21.2115C21.3243 16.1762 22.791 17.3787 24.6337 17.3787C26.1881 17.3787 27.2286 16.6647 27.8052 15.6877L29.673 17.0154C28.6702 18.5937 26.9403 19.5958 24.6086 19.5958C21.2366 19.5958 18.8047 17.2033 18.8047 13.8213C18.8172 10.5521 21.2491 8.04688 24.6212 8.04688ZM21.3369 12.5687H27.5169C27.2536 10.9654 25.9625 10.1011 24.5334 10.1011C23.1044 10.1011 21.6628 10.9278 21.3369 12.5687Z"
            fill="white"
          />
          <path
            d="M32.4609 8.25982H34.9054V9.7504C35.5321 8.86106 36.886 8.04688 38.478 8.04688C41.5241 8.04688 43.8307 10.6397 43.8307 13.8213C43.8307 16.9904 41.5241 19.5958 38.478 19.5958C36.8734 19.5958 35.5196 18.7691 34.9054 17.8797V24.0049H32.4609V8.25982ZM38.0393 10.264C36.0586 10.264 34.7048 11.8172 34.7048 13.8213C34.7048 15.8255 36.0586 17.3787 38.0393 17.3787C39.9948 17.3787 41.3486 15.8255 41.3486 13.8213C41.3486 11.8172 39.9948 10.264 38.0393 10.264Z"
            fill="white"
          />
          <path
            d="M48.7647 2.70312H46.3203V19.3501H48.7647V2.70312Z"
            fill="white"
          />
          <path
            d="M53.2789 5.83486C52.3889 5.83486 51.6367 5.10836 51.6367 4.19397C51.6367 3.30463 52.3889 2.57812 53.2789 2.57812C54.194 2.57812 54.896 3.31715 54.896 4.19397C54.896 5.10836 54.194 5.83486 53.2789 5.83486ZM52.0629 8.25237H54.5074V19.3503H52.0629V8.25237Z"
            fill="white"
          />
          <path
            d="M62.851 19.5801C59.5166 19.5801 56.9844 17.0875 56.9844 13.8057C56.9844 10.5239 59.5166 8.03125 62.851 8.03125C65.145 8.03125 67.0755 9.25879 68.0282 11.1001L65.8971 12.2525C65.3581 11.1252 64.2801 10.2984 62.851 10.2984C60.8704 10.2984 59.479 11.8266 59.479 13.8057C59.479 15.7848 60.8829 17.313 62.851 17.313C64.2675 17.313 65.3581 16.4862 65.8971 15.3589L68.0282 16.5113C67.0755 18.3651 65.1325 19.5801 62.851 19.5801Z"
            fill="white"
          />
          <path
            d="M74.8214 8.04688C76.426 8.04688 77.7547 8.87359 78.3815 9.7504V8.25982H80.826V19.3578H78.3815V17.8672C77.7547 18.7565 76.426 19.5832 74.8214 19.5832C71.7753 19.5832 69.4688 16.9904 69.4688 13.8088C69.4688 10.6397 71.7753 8.04688 74.8214 8.04688ZM75.2727 10.264C73.2921 10.264 71.9633 11.8172 71.9633 13.8213C71.9633 15.8255 73.2921 17.3787 75.2727 17.3787C77.2533 17.3787 78.5821 15.8255 78.5821 13.8213C78.5821 11.8172 77.2408 10.264 75.2727 10.264Z"
            fill="white"
          />
          <path
            d="M85.0144 19.3638V10.4328H82.6953V8.25325H85.0144V5.17188H87.4588V8.25325H91.6708V10.4328H87.4588V17.1717H91.6708V19.3638H85.0144Z"
            fill="white"
          />
          <path
            d="M17.663 8.25V10.442H11.671V19.3605H9.22656V8.25H17.663Z"
            fill="white"
          />
          <path
            d="M17.6667 4.13281V6.31232H7.06162V19.3643H4.61719V4.13281H17.6667Z"
            fill="white"
          />
          <path
            d="M17.6626 0V2.17951H2.44443V19.3651H0V0H17.6626Z"
            fill="white"
          />
          <path
            d="M98.6485 8.04688C101.557 8.04688 104.001 9.97587 104.001 13.5583C104.001 13.7963 104.001 13.9967 103.976 14.3599H95.2388C95.3517 16.1762 96.8183 17.3787 98.6611 17.3787C100.215 17.3787 101.256 16.6647 101.833 15.6877L103.7 17.0154C102.697 18.5937 100.968 19.5958 98.636 19.5958C95.2639 19.5958 92.832 17.2033 92.832 13.8213C92.8571 10.5521 95.2765 8.04688 98.6485 8.04688ZM95.3642 12.5687H101.544C101.281 10.9654 99.9898 10.1011 98.5608 10.1011C97.1442 10.1011 95.7027 10.9278 95.3642 12.5687Z"
            fill="white"
          />
        </svg>
      </a>
      <Ariakit.Menu
        store={menu}
        modal
        getAnchorRect={() => anchorRect}
        className="bg-white p-2"
      >
        <Ariakit.MenuItem
          autoFocus
          onClick={handleDownloadGlyph}
          className="menu-item"
        >
          <span className="w-5 h-5 flex-shrink-0">
            <LogoGlyph />
          </span>
          <span>Download logo glyph as SVG</span>
        </Ariakit.MenuItem>
        <Ariakit.MenuItem
          onClick={handleDownloadWordmark}
          className="menu-item"
        >
          <span className="w-5 h-5 flex-shrink-0">
            <TextAa size={20} />
          </span>
          <span>Download wordmark as SVG</span>
        </Ariakit.MenuItem>
        <Ariakit.MenuItem
          className="menu-item"
          // biome-ignore lint/a11y/useAnchorContent: <explanation>
          render={<a href="https://replicate.com/andreasjansson/plasma" />}
        >
          <div className="w-5 h-5 flex-shrink-0 relative overflow-hidden">
            <div className="absolute inset-0 w-full h-full bg-gradient-to-tr from-brand-red via-brand-pink to-brand-yellow" />
          </div>
          <span>Generate your own goo</span>
        </Ariakit.MenuItem>
      </Ariakit.Menu>
    </>
  );
}
